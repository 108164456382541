import type {
  SlideImageTypes,
  LineTypes,
} from 'components/UniqueProposition/types';

export const uniquePropositionSliderSettings = {
  dots: false,
  infinite: true,
  fade: true,
  slidesToShow: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToScroll: 1,
  speed: 1500,
  cssEase: 'linear',
};

export const slidesFirstLineImage: SlideImageTypes[] = [
  {
    src: '/img/UniqueProposition/1_1_man.jpg',
    srcMobile: '/img/UniqueProposition/1_1_man_mobile.jpg',
  },
  {
    src: '/img/UniqueProposition/1_2_woman.jpg',
    srcMobile: '/img/UniqueProposition/1_2_woman_mobile.jpg',
  },
  {
    src: '/img/UniqueProposition/1_3_grandfather.jpg',
    srcMobile: '/img/UniqueProposition/1_3_grandfather_mobile.jpg',
  },
];

export const slidesSecondLineImage: SlideImageTypes[] = [
  {
    src: '/img/UniqueProposition/2_1_kids.jpg',
    srcMobile: '/img/UniqueProposition/2_1_kids_mobile.jpg',
  },
  {
    src: '/img/UniqueProposition/2_2_grandma.jpg',
    srcMobile: '/img/UniqueProposition/2_2_grandma_mobile.jpg',
  },
  {
    src: '/img/UniqueProposition/2_3_man.jpg',
    srcMobile: '/img/UniqueProposition/2_3_man_mobile.jpg',
  },
];

export const slidesThirdLineImage: SlideImageTypes[] = [
  {
    src: '/img/UniqueProposition/3_1_family.jpg',
    srcMobile: '/img/UniqueProposition/3_1_family_mobile.jpg',
  },
  {
    src: '/img/UniqueProposition/3_2_dog.jpg',
    srcMobile: '/img/UniqueProposition/3_2_dog_mobile.jpg',
  },
  {
    src: '/img/UniqueProposition/3_3_mom.jpg',
    srcMobile: '/img/UniqueProposition/3_3_mom_mobile.jpg',
  },
];

export const firstLine: LineTypes = {
  firstHead: `Оплачивайте 
недвижимость 
в Москве`,
  secondHead: `из любой точки 
мира`,
  slidesImages: slidesFirstLineImage,
};

export const secondLine: LineTypes = {
  firstHead: `Напомним 
и поможем`,
  secondHead: `вовремя оплатить 
коммуналку`,
  slidesImages: slidesSecondLineImage,
};

export const thirdLine: LineTypes = {
  firstHead: `Экономьте 
время`,
  secondHead: 'с МосПлатежами',
  slidesImages: slidesThirdLineImage,
};
