import { QrBlockImageAltEnum, StepsImageEnum } from 'components/QrBlock/types';

export const stepsDetail = {
  firstStep: `Отсканируйте QR-код на 
квитанции через приложение`,
  secondStep: `Оплатите найденный счет, 
передайте счетчики`,
  thirdStep: `Дальше платите уже без 
бумажных квитанций`,
};

export const stepsData = [
  {
    id: 0,
    image: StepsImageEnum.one,
    imageAlt: QrBlockImageAltEnum.one,
    imgWidth: 9,
    detailText: stepsDetail.firstStep,
  },
  {
    id: 1,
    image: StepsImageEnum.two,
    imageAlt: QrBlockImageAltEnum.two,
    imgWidth: 17,
    detailText: stepsDetail.secondStep,
  },
  {
    id: 2,
    image: StepsImageEnum.three,
    imageAlt: QrBlockImageAltEnum.three,
    imgWidth: 17,
    detailText: stepsDetail.thirdStep,
  },
];
