export function debounce<T extends (...args: unknown[]) => void>(
  func: T,
  wait: number,
): () => void {
  let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  return function executedFunction(...args: Parameters<T>): void {
    const later = (): void => {
      timeout = undefined;
      func(...args);
    };

    if (timeout !== undefined) {
      clearTimeout(timeout as ReturnType<typeof setTimeout>);
    }

    timeout = setTimeout(later, wait);
  };
}
