import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { Subtitle1 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import Image from 'next/image';
import type { FC } from 'react';

import s from 'components/HeroBlock/components/HeroItem/HeroItem.module.scss';
import type { HeroItemTypes } from 'components/HeroBlock/components/types';

const HeroItem: FC<HeroItemTypes> = ({
  title,
  image,
  alt,
  className,
  ...restProps
}) => {
  return (
    <Typograf>
      <div className={cn(s.wrapper, className)} {...restProps}>
        <Image
          src={`/img/HeroBlock/${image}`}
          width={64}
          height={64}
          alt={alt}
          className={s.image}
        />
        <Subtitle1 className={s.subtitle}>{title}</Subtitle1>
      </div>
    </Typograf>
  );
};

export default HeroItem;
