import type { AdvantagesCardTypes } from 'components/Advantages/types';
import { CardImagesEnum } from 'components/Advantages/types';

export const advantagesCardsData: AdvantagesCardTypes[] = [
  {
    image: CardImagesEnum.search,
    title: `Поиск счетов 
по адресу`,
    alt: 'Изображение лупы',
  },
  {
    image: CardImagesEnum.clock,
    title: `Уведомления об оплате 
и передаче счетчиков`,
    alt: 'Изображение будильника',
  },
  {
    image: CardImagesEnum.qrCode,
    title: `Оплата 
по QR-коду`,
    alt: 'Изображение кода оплаты',
  },
  {
    image: CardImagesEnum.light,
    title: `Передача 
счетчиков`,
    alt: 'Изображение лампочки',
  },
  {
    image: CardImagesEnum.mail,
    title: `Оплата 
без квитанций`,
    alt: 'Изображение конверта',
  },
  {
    image: CardImagesEnum.point,
    title: `Оплата нескольких квартир 
в одном приложении`,
    alt: 'Изображение маркера',
  },
];
