import ALink from '@a3/chameleon/src/components/basic/ALink';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { Subtitle2 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import { ImagesPathEnum } from 'components/HeroBlock/components/types';

import s from './mobileContent.module.scss';

const MobileContent: FC = () => {
  const { clickAnalytics } = useAnalytics();

  return (
    <div className={cn(s.wrapper, 'g-col-xss-4')}>
      <Typograf>
        <Subtitle2 className={s.subtitle2}>Скачивайте приложение</Subtitle2>
        <div className={s.iconWrapper}>
          <div className={s.icons}>
            <ALink
              href={pagesUrls.mobileAppStore}
              target="_blank"
              onClick={(): void => clickAnalytics(gtmEvents.clickMainAppStore)}
            >
              <img
                src={ImagesPathEnum.appStore}
                className={s.img}
                alt="AppStore"
              />
            </ALink>
            <ALink
              href={pagesUrls.mobileGooglePlay}
              target="_blank"
              onClick={(): void =>
                clickAnalytics(gtmEvents.clickMainGooglePlay)
              }
            >
              <img
                src={ImagesPathEnum.googlePlay}
                className={s.img}
                alt="GooglePlay"
              />
            </ALink>
          </div>
        </div>
      </Typograf>
    </div>
  );
};

export default MobileContent;
