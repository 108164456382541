import type { DivProps } from 'app.types';

import { gtmEvents } from 'constants/gtmEvent';

export type QrBlockTypes = DivProps;

export enum QrBlockImageEnum {
  background = '/img/QrBlock/qrCode.svg',
  lineBezier = '/img/QrBlock/vector.svg',
  handWithPhone = '/img/QrBlock/hand.png',
}

export enum QrBlockImageAltEnum {
  background = 'Фоновое изображение qr кода',
  lineBezier = 'Кривая линия на фоне',
  one = 'Изображение первого шага',
  two = 'Изображение второго шага',
  three = 'Изображение третьего шага',
  handWithPhone = 'Изображение руки с телефоном',
}

export enum StepsImageEnum {
  one = 'one',
  two = 'two',
  three = 'three',
}

export const classBlock = '.js-download-application';
export const analyticsEvent = gtmEvents.clickDownloadStore;
