import ALink from '@a3/chameleon/src/components/basic/ALink';

import type { FaqCardDataTypes } from 'components/Faq/types';

import { pagesUrls } from '../pagesComponents/HotWaterCutPage/constants';

import s from './Faq.module.scss';

export const faqCardData: FaqCardDataTypes[] = [
  {
    title: 'Где посмотреть график отключения воды в Москве 2024?',
    body: (
      <span>
        Посмотреть информацию по графику отключения воды можно на этой{' '}
        <ALink className={s.link} href={pagesUrls.hotWaterCut}>
          странице
        </ALink>
        .
      </span>
    ),
  },
  {
    title: 'Какие коммунальные услуги можно оплачивать в приложении?',
    body:
      'В приложении МосПлатежи вы можете оплатить все виды коммунальных услуг,' +
      ' которые входят в ЕПД - единый платежный документ по г. Москва (холодное водоснабжение, горячее водоснабжение, водоотведение, отопление,' +
      ' взнос на капитальный ремонт, содержание жилого помещения и обращение с ТКО, домофон, газ, антенну, радиоточку) и электроэнергию.' +
      ' В приложении можно передать показания счетчиков по всем услугам ЖКХ, где у вас установлены приборы учета потребления.',
  },
  {
    title:
      'Как я могу узнать свою задолженность за жилищно-коммунальные услуги (ЖКУ)?',
    body:
      'Для того чтобы получить информацию о задолженности за услуги ЖКХ,' +
      ' необходимо указать свой адрес либо ввести лицевой счет с квитанции,' +
      ' либо отсканировать QR-код с квитанции, тогда в приложении автоматически отобразится задолженность.',
  },
  {
    title:
      'Какие документы я могу получить в качестве подтверждения оплаты услуг?',
    body: (
      <span>
        После осуществления платежа Вам будет доступна квитанция об оплате,
        которую можно распечатать или отправить на свою электронную почту. Кроме
        того, история всех Ваших платежей хранится в приложении, где Вы в любой
        момент можете посмотреть данные. Если Вам необходим документ, заверенный
        печатью, Вы можете запросить его в техподдержке приложения, написав в
        чат или на почту{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com
        </ALink>
        .
      </span>
    ),
  },
  {
    title: 'Как настроить уведомления о новых начислениях в приложении',
    body:
      'Подписка в приложении на уведомления о новых выставленных счетах' +
      ' за жилищно-коммунальные услуги создается автоматически. Когда вы в первый' +
      ' раз оплатите квитанцию любым способом, приложение запомнит ваш адрес и' +
      ' лицевой счет и будет сообщать о всех последующих начислениях за ЖКУ. ',
  },
  {
    title: 'Как передать показания счетчиков',
    body:
      'В приложении вы можете передать показания счетчиков за воду, электроэнергию и' +
      ' газ вместе с оплатой коммунальных начислений за предыдущий период.',
  },
  {
    title:
      'Можно ли оплатить картой “Халва” (кредитной картой) коммунальные услуги?',
    body:
      'Да, можно. В приложении “МосПлатежи” к оплате принимаются все виды карт' +
      ' платежных систем VISA, MasterCard (в том числе Maestro) и МИР (при наличии кода CVV/CVC),' +
      ' выпущенные кредитными организациями Российской Федерации. Также можно оплатить по СБП' +
      ' через приложение удобного для вас банка. После первой успешной оплаты вы можете сохранить' +
      ' карту для будущих платежей и не вводить реквизиты каждый раз заново.',
  },
  {
    title: 'Как оплатить ЖКХ социальной картой москвича? ',
    body:
      'Социальной картой москвича оплатить ЖКХ в приложении нельзя. Карты, на которых отсутствует' +
      ' CVC/CVV код (три последние цифры, указанные на обороте карты), не предусмотрены для оплаты товаров' +
      ' и услуг через Интернет. Оплатить начисления можно только с помощью платежных систем VISA, MasterCard' +
      ' (в том числе Maestro) и МИР (при наличии кода CVV/CVC), выпущенные кредитными организациями Российской Федерации.',
  },
  {
    title: 'Новые правила оплаты услуг ЖКХ в 2024',
    body: (
      <>
        <span>
          В 2024 году льготникам установят скидки на оплату жилищно-коммунальных
          услуг. Деньги будут поступать напрямую в ресурсоснабжающие
          организации, а не льготникам. По новым правилам, льготники сразу будут
          платить меньше. Скидка составит от 30% до 50% процентов, в зависимости
          от категории. Подробнее об этом можно прочитать по{' '}
          <ALink
            className={s.link}
            href="https://xn--h1alcedd.xn--d1aqf.xn--p1ai/news/lgotnikam-ustanovyat-skidki-na-oplatu-zhku/"
            target="_blank"
          >
            ссылке
          </ALink>
          .
        </span>
        <br />
        <span>
          Правительство утвердило предельный рост тарифов ЖКХ на 2024 год. Со
          второго полугодия 2024 года предельное подорожание услуг ЖКХ в Москве
          составит 11%, Подмосковье — 10,7%. Подробно об изменениях можно
          прочитать по{' '}
          <ALink
            className={s.link}
            href="https://www.rbc.ru/economics/13/11/2023/655243349a794727546a0fda "
            target="_blank"
          >
            ссылке
          </ALink>
          .
        </span>
      </>
    ),
  },
  {
    title: 'Я не нашел в списке своего поставщика коммунальных услуг.',
    body:
      'На данный момент  доступны 2 поставщика: ЕИРЦ Москвы и Мосэнергосбыт.' +
      'Когда будут подключены новые поставщики, мы обязательно уведомим Вас об этом.',
  },
];

export const faqCardDataAll: FaqCardDataTypes[] = [
  {
    title: 'Где посмотреть график отключения воды в Москве 2024?',
    body: (
      <span>
        Посмотреть информацию по графику отключения воды можно на этой{' '}
        <ALink className={s.link} href={pagesUrls.hotWaterCut}>
          странице
        </ALink>
        .
      </span>
    ),
  },
  {
    title: 'Какие коммунальные услуги можно оплачивать в приложении?',
    body:
      'В приложении МосПлатежи вы можете оплатить все виды коммунальных услуг,' +
      ' которые входят в ЕПД - единый платежный документ по г. Москва (холодное водоснабжение, горячее водоснабжение, водоотведение, отопление,' +
      ' взнос на капитальный ремонт, содержание жилого помещения и обращение с ТКО, домофон, газ, антенну, радиоточку) и электроэнергию.' +
      ' В приложении можно передать показания счетчиков по всем услугам ЖКХ, где у вас установлены приборы учета потребления.',
  },
  {
    title:
      'Как я могу узнать свою задолженность за жилищно-коммунальные услуги (ЖКУ)?',
    body:
      'Для того чтобы получить информацию о задолженности за услуги ЖКХ,' +
      ' необходимо указать свой адрес, либо ввести лицевой счет с квитанции,' +
      ' либо отсканировать QR-код с квитанции, тогда в приложении автоматически отобразится задолженность.',
  },
  {
    title:
      'Какие документы я могу получить в качестве подтверждения оплаты услуг?',
    body: (
      <span>
        После осуществления платежа Вам будет доступна квитанция об оплате,
        которую можно распечатать или отправить на свою электронную почту. Кроме
        того, история всех Ваших платежей хранится в приложении, где Вы в любой
        момент можете посмотреть данные. Если Вам необходим документ, заверенный
        печатью, Вы можете запросить его в техподдержке приложения, написав в
        чат или на почту{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com
        </ALink>
        .
      </span>
    ),
  },
  {
    title: 'Как настроить уведомления о новых начислениях в приложении',
    body:
      'Подписка в приложении на уведомления о новых выставленных счетах' +
      ' за жилищно-коммунальные услуги создается автоматически. Когда вы в первый' +
      ' раз оплатите квитанцию любым способом, приложение запомнит ваш адрес и' +
      ' лицевой счет и будет сообщать о всех последующих начислениях за ЖКУ. ',
  },
  {
    title: 'Как передать показания счетчиков',
    body:
      'В приложении вы можете передать показания счетчиков за воду, электроэнергию,' +
      ' газ вместе с оплатой коммунальных начислений за предыдущий период.',
  },
  {
    title:
      'Можно ли оплатить картой “Халва” (кредитной картой) коммунальные услуги?',
    body:
      'Да, можно. В приложении “МосПлатежи” к оплате принимаются все виды карт' +
      ' платежных систем VISA, MasterCard (в том числе Maestro) и МИР (при наличии кода CVV/CVC),' +
      ' выпущенные кредитными организациями Российской Федерации. Также можно оплатить по СБП' +
      ' через приложение удобного для вас банка. После первой успешной оплаты вы можете сохранить' +
      ' карту для будущих платежей и не вводить реквизиты каждый раз заново.',
  },
  {
    title: 'Как оплатить ЖКХ социальной картой москвича? ',
    body:
      'Социальной картой москвича оплатить ЖКХ в приложении нельзя. Карты, на которых отсутствует' +
      ' CVC/CVV код (три последние цифры, указанные на обороте карты), не предусмотрены для оплаты товаров' +
      ' и услуг через Интернет. Оплатить начисления можно только с помощью платежных систем VISA, MasterCard' +
      ' (в том числе Maestro) и МИР (при наличии кода CVV/CVC), выпущенные кредитными организациями Российской Федерации.',
  },
  {
    title: 'Новые правила оплаты услуг ЖКХ в 2024',
    body: (
      <>
        <span>
          В 2024 году льготникам установят скидки на оплату ЖКУ.. Деньги будут
          поступать напрямую в ресурсоснабжающие организации, а не льготникам.
          По новым правилам, льготники сразу будут платить меньше. Скидка
          составит от 30% до 50% процентов, в зависимости от категории.
          Подробнее об этом можно прочитать по{' '}
          <ALink
            className={s.link}
            href="https://xn--h1alcedd.xn--d1aqf.xn--p1ai/news/lgotnikam-ustanovyat-skidki-na-oplatu-zhku/"
            target="_blank"
          >
            ссылке
          </ALink>
          .
        </span>
        <br />
        <span>
          Правительство утвердило предельный рост тарифов ЖКХ на 2024 год. Со
          второго полугодия 2024 года предельное подорожание услуг ЖКХ в Москве
          составит 11%, Подмосковье — 10,7%. Подробно об изменениях можно
          прочитать по{' '}
          <ALink
            className={s.link}
            href="https://www.rbc.ru/economics/13/11/2023/655243349a794727546a0fda "
            target="_blank"
          >
            ссылке
          </ALink>
          .
        </span>
      </>
    ),
  },
  {
    title: 'Я не нашел в списке своего поставщика коммунальных услуг.',
    body:
      'На данный момент  доступны 2 поставщика: ЕИРЦ Москвы и Мосэнергосбыт.' +
      'Когда будут подключены новые поставщики, мы обязательно уведомим Вас об этом.',
  },
  /// Вопросы для отдельной страницы ⇣⇣⇣
  {
    title: 'Какие способы оплаты доступны в приложении?',
    body:
      'К оплате принимаются все виды карт платежных систем VISA, MasterCard (в том числе Maestro)' +
      ' и МИР (при наличии кода CVV/CVC), выпущенные кредитными организациями Российской Федерации. Yandex.Pay/',
  },
  {
    title:
      'Можно ли оплатить счет, выставленный в рублях, с карты в долларах или евро?',
    body:
      'Вы можете оплатить счет в рублях с карты в другой валюте. Обратите внимание,' +
      ' что при этом банк, выпустивший карту, самостоятельно проводит конвертацию валюты.' +
      ' Рекомендуем Вам заранее уточнить условия и принять во внимание, что у каждого банка существует свой обменный курс.',
  },
  {
    title:
      'Каким способом происходит оповещение о списании денежных средств с банковской карты?',
    body:
      'В случае успешного завершения оплаты Вы увидите соответствующий статус платежа на экране приложения.' +
      ' Также в приложении Вы сможете посмотреть квитанцию об оплате. Кроме того, если в Вашем банке подключена' +
      ' услуга информирования, то вы также получите дополнительное оповещение о списании денежных средств от банка.' +
      ' Если услуга не подключена, статус транзакции можно узнать, позвонив в банк.',
  },
  {
    title:
      'При каждой оплате нужно заново указывать данные карты. Как этого избежать?',
    body:
      'После первой успешной оплаты вы можете сохранить данные карты в приложении. При следующем платеже' +
      ' выберите нужную карту и укажите CVV/CVC код (он не хранится в системе согласно требованиям безопасности).',
  },
  {
    title: 'Возможна ли оплата с карты, на которой отсутствует CVC/CVV?',
    body:
      'Карты, на которых отсутствует CVC/CVV код, не предусмотрены для оплаты товаров и услуг через Интернет.' +
      'CVV/CVC код – это трехзначный код проверки подлинности банковской карты. Он находится на оборотной стороне' +
      'карты: либо на полосе для подписи держателя, либо после номера карты. Данный код используется для защиты' +
      'и обеспечения безопасности проводимых банковских операций.',
  },
  {
    title:
      'Я оплатил услуги ЖКХ через “МосПлатежи”, но средства не поступили' +
      'получателю/получил квитанцию с задолженностью. Что делать?',
    body: (
      <span>
        Для того чтобы решить данный вопрос, Вам необходимо обратиться в
        техподдержку приложения (вкладка “Профиль” → “Техподдержка”) либо
        написать нам на почту{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com
        </ALink>
        , чтобы уточнить статус платежа. Наши операторы ответят на все Ваши
        вопросы. Перечисление средств получателю осуществляется в течение 1-3
        рабочих дней (не считая дня совершения операции).
      </span>
    ),
  },
  {
    title:
      'Сколько по времени средства перечисляются поставщику после оплаты услуг ЖКХ?',
    body: (
      <span>
        Как правило, денежные средства перечисляются поставщику в течение 1-3
        рабочих дней (не считая дня операции). Информация о платеже отражается в
        системе учета поставщика услуг (на лицевом счете) в режиме реального
        времени (онлайн) или на следующий за датой оплаты рабочий день. Если
        хотите уточнить статус платежа то напишите в техподдержку приложения
        (вкладка “Профиль” → “Техподдержка”) или на почту{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com
        </ALink>
        .
      </span>
    ),
  },
  {
    title: 'Почему через МосПлатежи платить безопасно?',
    body:
      'Приложение МосПлатежи– выпущено ООО “Платежный сервис А3”, который работает на рынке с 2010 года.' +
      'Платежные услуги в мобильном приложении «МосПлатежи» оказывает ПАО «Промсвязьбанк» генеральная лицензия' +
      ' Банка России 3251 от 17.12.2014. Данные защищены в соответствии со стандартом безопасности PCI DSS.' +
      ' Стандарт разработан платежными системами VISA и MasterCard. Работа с картами осуществляется по технологии 3-D Secure.' +
      ' Мы не храним данные вашей карты у себя в приложении.',
  },
  {
    title: 'Что такое PСI DSS?',
    body:
      'PСI DSS (Payment Card Industry Data Security Standard) – стандарт безопасности данных, разработанный Советом' +
      ' по стандартам безопасности индустрии платежных карт (Payment Card Industry Security Standards Council, PCI SSC)' +
      ' и учрежденный компаниями Visa, MasterCard, American Express, JCB и Discover. Он подразумевает комплексный подход к' +
      ' обеспечению информационной безопасности данных платежных карт и детально регламентирует требования по конфиденциальности' +
      ' данных о держателях карт. В него входит построение и сопровождение защищенной сети; защита данных держателей карт;' +
      ' поддержка программы управления уязвимостями; реализация мер по строгому контролю доступа; регулярный мониторинг и тестирование сети;' +
      ' поддержка политики информационной безопасности; разработка, поддержка и исполнение политики информационной безопасности.' +
      ' Ознакомиться с сертификатом можно по ссылке..',
  },
  {
    title: 'Что такое 3-D Secure?',
    body:
      '3-D Secure – технология, которая создает дополнительный уровень безопасности для оплаты банковскими картами в Интернете.' +
      ' 3-D Secure позволяет проводить аутентификацию пользователя (держателя карты) путем ввода кода подтверждения операции.' +
      ' Код направляется эмитентом карты в виде SMS-сообщения на номер, указанный держателем при подключении информирования.' +
      ' Технология разработана компанией Visa. Услуги на основе данного протокола также приняты компанией MasterCard.',
  },
  {
    title: 'Что является подтверждением оплаты услуги?',
    body: (
      <span>
        Подтверждением оплаты услуги через приложение МосПлатежи является
        электронная квитанция. При необходимости Вы можете заказать почтовую
        доставку справки на бумажном носителе, обратившись по почте{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com.
        </ALink>{' '}
        Доставка документа в бумажном виде с подписью уполномоченного работника
        и печатью осуществляется бесплатно. По платежам, совершаемым в бюджет РФ
        (штрафы ГИБДД, налоги), Вы также можете запросить платежное поручение,
        подтверждающее перечисление денежных средств. Вы можете запросить его в
        техподдержке приложения написав в чат или на почту{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com
        </ALink>
      </span>
    ),
  },
  {
    title:
      'Как я могу отозвать свое согласие на обработку персональных данных?',
    body: (
      <span>
        Согласие пользователя на обработку персональных данных может быть
        отозвано в любое время. В соответствии с требованиями действующего
        законодательства Российской Федерации отзыв согласия должен быть
        направлен пользователем в письменном виде на почтовый адрес{' '}
        <ALink className={s.link} href="mailto:support@mosplatezhi.com">
          support@mosplatezhi.com
        </ALink>
        .
      </span>
    ),
  },
];
