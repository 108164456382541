import ALink from '@a3/chameleon/src/components/basic/ALink';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import {
  H2,
  Subtitle1,
  Subtitle2,
} from '@a3/chameleon/src/components/basic/Typography';
import type { DivProps } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import Image from 'next/image';
import React from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import {
  ImageAltTextEnum,
  ImagesPathEnum,
} from 'components/DownloadApplication/types';
import ContentContainer from 'components/basic/ContentContainer';

import s from './DownloadApplication.module.scss';

type DownloadApplicationTypes = DivProps & {
  qrCodeWithMetrics?: string;
  storeUrlsWithMetrics?: Record<string, string>;
};

const DownloadApplication: FC<DownloadApplicationTypes> = ({
  className,
  qrCodeWithMetrics,
  storeUrlsWithMetrics,
}) => {
  const { clickAnalytics } = useAnalytics();

  return (
    <Typograf>
      <div className={cn(s.wrapperBg, className)} id="js-download-application">
        <ContentContainer className={cn(s.container, 'grid')}>
          <H2 className={cn(s.head, 'g-col-s-8 g-start-xss-1')}>
            Скачайте приложение
            <div className={s.mosPaymentsWrapper}>
              <span className={s.mosPayments}>МосПлатежи</span>
              бесплатно
            </div>
          </H2>
          <Subtitle2 className={s.subtitleApple}>
            Доступно для устройств iOS и Android
          </Subtitle2>
          <div
            className={cn(
              s.wrapperQr,
              'g-col-m-6 g-col-s-8 g-col-xss-4 g-start-xss-1',
            )}
          >
            <div className={s.imageQr}>
              <Image
                src={
                  qrCodeWithMetrics ? qrCodeWithMetrics : ImagesPathEnum.qrCode
                }
                width={100}
                height={100}
                alt={ImageAltTextEnum.qrCode}
              />
            </div>
            <Subtitle1 className={s.subtitleQR}>
              {`Оплата по QR-коду
Оплата из любой точки
Безопасные платежи`}
            </Subtitle1>
          </div>
          <Subtitle2 className={s.subtitleAppleMobile}>
            Доступно для устройств iOS и Android
          </Subtitle2>
          <div className={s.wrapperLogoAppleGoogle}>
            <ALink
              href={
                storeUrlsWithMetrics
                  ? storeUrlsWithMetrics.mobileAppStore
                  : pagesUrls.mobileAppStore
              }
              target="_blank"
              className={s.link}
              onClick={(): void =>
                clickAnalytics(gtmEvents.clickDownloadAppStore)
              }
            >
              <Image
                className={s.linkIcon}
                src={ImagesPathEnum.appStore}
                width={146}
                height={48}
                alt={ImageAltTextEnum.appStore}
              />
            </ALink>
            <ALink
              href={
                storeUrlsWithMetrics
                  ? storeUrlsWithMetrics.mobileGooglePlay
                  : pagesUrls.mobileGooglePlay
              }
              target="_blank"
              className={s.link}
              onClick={(): void =>
                clickAnalytics(gtmEvents.clickDownloadGooglePlay)
              }
            >
              <Image
                className={s.linkIcon}
                src={ImagesPathEnum.googlePlay}
                width={146}
                height={48}
                alt={ImageAltTextEnum.googlePlay}
              />
            </ALink>
          </div>
          <Image
            className={s.imageRing}
            src={ImagesPathEnum.ring}
            width={471}
            height={420}
            alt={ImageAltTextEnum.ring}
            priority
          />

          <Image
            className={cn(
              s.imagePhone,
              'g-col-s-4 g-col-xss-3 g-start-m-9 g-start-xss-1',
            )}
            src={ImagesPathEnum.phone}
            width={385}
            height={385}
            alt={ImageAltTextEnum.phone}
          />

          <Image
            className={cn(
              s.imageNotice,
              'g-col-s-4 g-col-xss-3 g-start-m-7 g-start-xss-1',
            )}
            src={ImagesPathEnum.notification}
            width={426}
            height={116}
            alt={ImageAltTextEnum.notification}
          />
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default DownloadApplication;
