import cn from 'classnames';
import Image from 'next/image';
import type { FC } from 'react';

import DesktopMenu from 'components/HeroBlock/components/DesktopMenu';
import MobileMenu from 'components/HeroBlock/components/MobileMenu';
import s from 'components/HeroBlock/components/NavigationMenu/NavigationMenu.module.scss';
import type { NavigationMenuTypes } from 'components/HeroBlock/components/types';
import { ImagesPathEnum } from 'components/HeroBlock/components/types';
import ContentContainer from 'components/basic/ContentContainer';

const NavigationMenu: FC<NavigationMenuTypes> = ({ className }) => {
  return (
    <ContentContainer className={cn(s.wrapper, className)}>
      <Image
        src={ImagesPathEnum.whiteLogo}
        width={164}
        height={32}
        priority
        alt="Логотип сервиса"
      />

      <DesktopMenu />
      <MobileMenu />
    </ContentContainer>
  );
};

export default NavigationMenu;
