import type { FC } from 'react';

import Advantages from 'components/Advantages';
import DownloadApplication from 'components/DownloadApplication';
import Faq from 'components/Faq';
import HeroBlock from 'components/HeroBlock';
import QrBlock from 'components/QrBlock';
import ReviewSlider from 'components/ReviewSlider';
import UniqueProposition from 'components/UniqueProposition';
import {
  firstLine,
  secondLine,
  thirdLine,
} from 'components/UniqueProposition/data';
import Page from 'components/layouts/Page';

const MainLandingPage: FC = () => {
  return (
    <Page withoutHeader>
      <HeroBlock />
      <Advantages className="js-advantages" />
      <QrBlock />
      <UniqueProposition {...firstLine} className="js-unique-proposition" />
      <UniqueProposition isReverse {...secondLine} />
      <UniqueProposition {...thirdLine} />
      <DownloadApplication className="js-download-application" />
      <ReviewSlider className="js-review-slider" />
      <Faq className="js-faq" />
    </Page>
  );
};

export default MainLandingPage;
