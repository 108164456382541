import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import Image from 'next/image';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import type { CustomArrowsTypes } from './types';

import s from './CustomArrows.module.scss';

const CustomArrows: FC<CustomArrowsTypes> = ({
  previousSlide,
  nextSlide,
  className,
  ...restProps
}) => {
  const { clickAnalytics } = useAnalytics();

  const handlePreviousClick = (): void => {
    clickAnalytics(gtmEvents.clickReviewArrowPrev);
    if (previousSlide) {
      previousSlide();
    }
  };

  const handleNextClick = (): void => {
    clickAnalytics(gtmEvents.clickReviewArrowNext);
    if (nextSlide) {
      nextSlide();
    }
  };

  return (
    <div className={cn(s.arrowsContainer, className)} {...restProps}>
      <div className={s.arrowWrapper} onClick={handlePreviousClick}>
        <Image
          src="/img/ReviewSlider/arrow-left.svg"
          width={24}
          height={24}
          alt="Стрелка влево"
        />
      </div>
      <div className={s.arrowWrapper} onClick={handleNextClick}>
        <Image
          src="/img/ReviewSlider/arrow-right.svg"
          width={24}
          height={24}
          alt="Стрелка вправо"
        />
      </div>
    </div>
  );
};

export default CustomArrows;
