export const pagesUrls = {
  hotWaterCut: '/news/hot-water-cut',
  mobileAppStore:
    'https://redirect.appmetrica.yandex.com/serve/1181694482962210038',
  mobileGooglePlay:
    'https://redirect.appmetrica.yandex.com/serve/28772981878637023',
};

export const hotWaterCutPageTitle =
  'График отключения горячей воды 2024 в Москве по адресам - МосПлатежи';
export const hotWaterCutPageDescription =
  'С мая по конец августа 2024 года в Москве отключают горячую воду для проведения профилактических работ. Воду перекроют не более чем на 10 дней — общий срок отключения горячей воды не может превышать 240 часов. Подробности и графики отключения воды на сайте.';
