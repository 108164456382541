import { useEffect, useState } from 'react';

import { debounce } from 'utils/debounce';

interface useMobileTypes {
  width?: number;
}

function useMobile({ width = 768 }: useMobileTypes): { isMobile: boolean } {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const checkIsMobile = (): void => {
    const userWidth = window.innerWidth;
    setIsMobile(userWidth <= width);
  };

  const debouncedCheckIsMobile = debounce(checkIsMobile, 300);

  useEffect(() => {
    debouncedCheckIsMobile(); // Проверяем при монтировании компонента

    window.addEventListener('resize', debouncedCheckIsMobile); // Проверяем при изменении размеров окна

    return () => {
      window.removeEventListener('resize', debouncedCheckIsMobile); // Очищаем слушатель при размонтировании компонента
    };
  }, []);

  return { isMobile };
}

export default useMobile;
