import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { H2 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useMobile from 'hooks/useMobile';
import type { FC } from 'react';

import { uniquePropositionSliderSettings } from 'components/UniqueProposition/data';
import type { UniquePropositionTypes } from 'components/UniqueProposition/types';
import ContentContainer from 'components/basic/ContentContainer';
import Carousel from 'components/complex/Carousel';

import s from './UniqueProposition.module.scss';

const UniqueProposition: FC<UniquePropositionTypes> = ({
  isReverse,
  firstHead,
  secondHead,
  slidesImages,
  className,
  ...restProps
}) => {
  const { isMobile } = useMobile({ width: 468 });
  const Slides = slidesImages.map(({ src, srcMobile }) => (
    <div key={src}>
      <img
        className={s.slide}
        src={isMobile ? srcMobile : src}
        loading="lazy"
        alt="Изображение пользователя приложения"
      />
    </div>
  ));

  return (
    <Typograf>
      <div className={cn(s.background, className)} {...restProps}>
        <ContentContainer className={cn(s.container, 'grid')}>
          <div
            className={cn(s.wrapperText, {
              [s.wrapperTextReverse]: isReverse,
            })}
          >
            <H2 className={s.firstHead}>{firstHead}</H2>
            <H2 className={s.secondHead}>{secondHead}</H2>
          </div>
          <div
            className={cn(s.wrapperSlider, {
              [s.wrapperSliderReverse]: isReverse,
            })}
          >
            <Carousel settings={uniquePropositionSliderSettings}>
              {Slides}
            </Carousel>
          </div>
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default UniqueProposition;
