import type { DivProps } from 'app.types';

import { gtmEvents } from 'constants/gtmEvent';

export type AdvantagesTypes = DivProps;

export enum CardImagesEnum {
  clock = 'clock',
  key = 'key',
  light = 'light',
  mail = 'mail',
  point = 'point',
  search = 'search',
  qrCode = 'qrCode',
}

export interface AdvantagesCardTypes {
  image: CardImagesEnum;
  title: string;
  alt: string;
}

export const classBlock = '.js-download-application';
export const analyticsEvent = gtmEvents.clickDownloadStoreAdvantages;
