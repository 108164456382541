import Button from '@a3/chameleon/src/components/basic/Button';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import {
  BodyM,
  Caption2,
  H2,
} from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import type { FC } from 'react';

import { stepsData } from 'components/QrBlock/constants';
import type { QrBlockTypes } from 'components/QrBlock/types';
import {
  QrBlockImageAltEnum,
  QrBlockImageEnum,
  classBlock,
  analyticsEvent,
} from 'components/QrBlock/types';
import ContentContainer from 'components/basic/ContentContainer';

import { handleScrollAndToggle } from 'utils/handleScrollAndToggle';

import s from './QrBlock.module.scss';

const QrBlock: FC<QrBlockTypes> = ({ className }) => {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();

  return (
    <Typograf>
      <div className={cn(s.wrapperBg, className)}>
        <Image
          className={s.backgroundImage}
          src={QrBlockImageEnum.background}
          alt={QrBlockImageAltEnum.background}
          width={799}
          height={422}
        />
        <ContentContainer className={cn(s.container, 'grid')}>
          <H2
            className={cn(s.title, 'g-col-m-6 g-col-s-8 g-col-xss-4 g-start-1')}
          >
            Оплачивайте ЖКХ
            <br /> в нашем приложении по QR-коду
          </H2>
          <BodyM
            className={cn(s.body, 'g-col-m-6 g-col-s-8 g-col-xss-4 g-start-1')}
          >
            В приложении
            <span className={s.mosPayments}>МосПлатежи</span>
            <br className={s.br} />
            вы также можете быстро и удобно оплатить счет за коммунальные услуги
            по QR-коду
          </BodyM>
          <div
            className={cn(
              s.numbersWrapper,
              'g-col-m-7 g-col-s-8 g-col-xss-4 g-start-1',
            )}
          >
            <Image
              className={s.lineBezier}
              src={QrBlockImageEnum.lineBezier}
              alt={QrBlockImageAltEnum.lineBezier}
              width={445}
              height={38}
            />
            {stepsData.map(({ id, image, imageAlt, imgWidth, detailText }) => (
              <div className={s.wrapperItem} key={id}>
                <div className={s.number}>
                  <Image
                    width={imgWidth}
                    height={26}
                    src={`/img/QrBlock/${image}.svg`}
                    alt={imageAlt}
                  />
                </div>
                <Caption2 className={s.text}>{detailText}</Caption2>
              </div>
            ))}
          </div>
          <Button
            className={cn(s.button, 'g-start-1 g-col-2')}
            size="medium"
            look="danger"
            onClick={(): void =>
              handleScrollAndToggle({
                router,
                clickAnalytics,
                classBlock,
                analyticsEvent,
              })
            }
          >
            Скачать
          </Button>
          <div
            className={cn(
              s.wrapperPhone,
              'g-col-m-5 g-col-s-4 g-col-xss-4 g-start-m-8 g-start-xss-1',
            )}
          >
            <Image
              className={cn(s.handWithPhone)}
              src={QrBlockImageEnum.handWithPhone}
              alt={QrBlockImageAltEnum.handWithPhone}
              width={463}
              height={419}
            />
          </div>

          <div
            className={cn(
              s.thoughts,
              'g-col-m-2 g-col-s-3 g-col-xss-2 g-start-m-11 g-start-s-5 g-start-xss-3 ',
            )}
          >
            После первой оплаты новый счет будет выставлен прямо в приложении,
            останется только передать счетчики и оплатить!
          </div>
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default QrBlock;
