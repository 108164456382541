export const reviewCardSettings = {
  dots: false,
  slidesToShow: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 840,
      settings: {
        centerMode: false,
        slidesToShow: 1,
      },
    },
  ],
};

export const reviewCardData = [
  {
    title: 'Очень спасает оплата по адресу',
    body:
      'Очень спасает оплата по адресу и возможность платить за несколько квартир в одном приложении, так как у меня несколько квартир в городе и еще служебное помещение.\n' +
      '\n' +
      'Много оплат и много инфы, поэтому я давно не помню лицевые счета) Просто вбиваю адрес, сохраняю его и плачу.\n' +
      '\n' +
      'Первый раз за все заплатил, адреса сохранились. Теперь захожу и вообще ничего не вбиваю, просто оплачиваю.\n',
  },
  {
    title: 'Не нужны лишние документы',
    body:
      'Переехали в свое жилье и оплачиваем жкх теперь тоже сами. Остановились на приложении мос платежи, так как это всегда под рукой (в телефоне), не нужны лишние документы или инфа для оплаты. \n' +
      '\n' +
      'Нашла по адресу наши показания, сохранила все данные и каждый месяц просто оплачиваю через телефон всю коммуналку. \n',
  },
  {
    title: 'Нет путаницы в оплатах',
    body:
      'Мне хозяйка квартиры показала мосплатежи, когда начала снимать жилье, что вот есть спец приложение для оплаты жкх.\n' +
      '\n' +
      'Платишь все сама здесь, никому ничего не отправляешь. Нет путаницы в оплатах и я всегда вижу реальные цифры за коммуналку. \n',
  },
  {
    title: 'Скачала приложение по рекомендации',
    body:
      'Скачала приложение по рекомендации. Удобно, что в одном месте можно и все оплатить, и передать счетчики. Раньше оплачивала через банк, а счетчики передавала через Moc.ру. Про счетчики все время забывала, приходилось ходить восстанавливать. \n' +
      '\n' +
      'Это все правда экономит время и не раздражает.\n' +
      '\n' +
      ' А еще было бы здорово, если бы можно было оплачивать все сразу одной кнопкой.\n',
  },
  {
    title: 'С первого раза',
    body: 'Получилось оплатить с первого раза, все работает.',
  },
  {
    title: 'Хорошее приложение',
    body: 'Хорошее приложение. Особенно понравилось что все сохраняется, куда платить и когда.',
  },
  {
    title: 'Правда удобно',
    body:
      'Не оплачивал коммуналку никогда) пришлось начать, когда приехал в свое жилье. Хотелось чего-то простого и понятного.\n' +
      '\n' +
      'Я ничего не понимаю в счетах, квитанциях и тд, поэтому для меня моспалтежи и оплата по адресу - лучший формат. Зашел, оплатил, вышел и забыл на месяц) Правда удобно)\n',
  },
  {
    title: 'Все, что мне нужно, есть в МосПлатежах',
    body:
      'Все оплаты у меня только через приложения и телефон. Никуда не хожу, ничего не вписываю. \n' +
      '\n' +
      'По жкх перешла на мосплатежи, потому что до этого платила в двух других приложениях, из-за этого иногда путалась.\n' +
      '\n' +
      'Сейчас все что мне нужно есть в мосплатежах: начисления, счетчики можно сразу внести и сообщения о том, что пора платить тоже приходят\n' +
      '\n' +
      'Добавили бы еще в это приложение оплату за телефон, то было бы замечательно\n',
  },
  {
    title: 'Главный плюс - оплата по адресу',
    body:
      'Сам в Москве не живу, но сдаю свою квартиру. Перед тем, как уехать решил попробовать несколько приложений для оплаты жкх.\n' +
      '\n' +
      'Остановился на мосплатежи. Новый продукт (как я понял), удобная внутрянка и, наверное, главный плюс оплата по адресу. \n' +
      '\n' +
      'Регистрируешься, вводишь адрес регистрации и автоматически подгружаются цифры. Оплачиваешь все сразу, не нужно переходить на другие площадки. \n',
  },
];
