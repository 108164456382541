import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { H2 } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import type { FC } from 'react';

import ReviewCard from 'components/ReviewSlider/ReviewCard';
import {
  reviewCardData,
  reviewCardSettings,
} from 'components/ReviewSlider/data';
import type { ReviewSliderTypes } from 'components/ReviewSlider/types';
import ContentContainer from 'components/basic/ContentContainer';
import CustomArrows from 'components/basic/CustomArrows';
import Carousel from 'components/complex/Carousel';

import s from './ReviewSlider.module.scss';

const ReviewSlider: FC<ReviewSliderTypes> = ({ className }) => {
  return (
    <div className={cn(s.wrapper, className)}>
      <Typograf>
        <ContentContainer className="grid">
          <H2 className={cn(s.title, 'g-col-xss-4 g-col-s-8')}>Отзывы</H2>
          <div className={cn(s.slider, 'g-col-xss-4 g-col-s-8 g-col-m-12')}>
            <Carousel
              settings={reviewCardSettings}
              customArrows={(previousSlide, nextSlide): JSX.Element => {
                return (
                  <CustomArrows
                    previousSlide={previousSlide}
                    nextSlide={nextSlide}
                  />
                );
              }}
            >
              {reviewCardData.map(({ title, body }) => {
                return <ReviewCard title={title} body={body} key={title} />;
              })}
            </Carousel>
          </div>
        </ContentContainer>
      </Typograf>
    </div>
  );
};

export default ReviewSlider;
