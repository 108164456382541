import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import {
  BodyM,
  Subtitle1,
} from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import type { FC } from 'react';

import type { ReviewCardTypes } from 'components/ReviewSlider/types';

import s from './ReviewCard.module.scss';

const ReviewCard: FC<ReviewCardTypes> = ({
  title,
  body,
  className,
  ...restProps
}) => {
  return (
    <div className={cn(s.wrapper, className)} {...restProps}>
      <Typograf>
        <Subtitle1 className={s.title}>{title}</Subtitle1>
        <BodyM className={s.body}>{body}</BodyM>
      </Typograf>
    </div>
  );
};

export default ReviewCard;
