import Accordion from '@a3/chameleon/src/components/basic/Accordion';
import Button from '@a3/chameleon/src/components/basic/Button';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import {
  BodyM,
  Subtitle1,
} from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import useMobile from 'hooks/useMobile';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import pagesUrls from 'constants/pagesUrls';

import type { FaqCardTypes } from 'components/Faq/types';
import { classBlock, analyticsEvent } from 'components/Faq/types';

import { handleScrollAndToggle } from 'utils/handleScrollAndToggle';

import s from './FaqWrapper.module.scss';

const FaqWrapper: FC<FaqCardTypes> = ({ data, className, ...restProps }) => {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();
  const { isMobile } = useMobile({ width: 768 });

  return (
    <div className={cn(s.wrapper, className)} {...restProps}>
      <Typograf>
        {data.map(({ title, body }) => {
          return (
            <Accordion
              key={title}
              className={s.accordion}
              getHeader={(isOpen, handleExpand): JSX.Element => (
                <div
                  className={cn('grid', s.accordionHeader)}
                  onClick={handleExpand}
                >
                  <Subtitle1
                    className={cn(
                      s.subtitle1,
                      'g-col-xss-4 g-col-s-7 g-col-m-11',
                    )}
                  >
                    {title}
                  </Subtitle1>
                  <span className={s.accordionArrow}>
                    <Image
                      src={`/img/FaqWrapper/${
                        isOpen ? 'arrow-up' : 'arrow-down'
                      }.svg`}
                      width={24}
                      height={24}
                      alt="Стрелка раскрытия блока"
                    />
                  </span>
                </div>
              )}
              content={
                <div className={s.accordionContentWrapper}>
                  <BodyM className={s.bodyContent}>{body}</BodyM>
                  <Button
                    className={s.button}
                    look="danger"
                    size="medium"
                    href={isMobile ? pagesUrls.downloadStore : undefined}
                    onClick={(): void => {
                      if (!isMobile) {
                        handleScrollAndToggle({
                          router,
                          clickAnalytics,
                          classBlock,
                          analyticsEvent,
                        });
                      }
                    }}
                  >
                    Скачать приложение
                  </Button>
                </div>
              }
            />
          );
        })}
      </Typograf>
    </div>
  );
};

export default FaqWrapper;
