import type { HeroItemsDataTypes } from 'components/HeroBlock/components/types';

export const heroItemsData: HeroItemsDataTypes[] = [
  {
    title: 'Поиск по адресу',
    image: 'home.svg',
    alt: 'Дом',
  },
  {
    title: 'Все адреса Москвы',
    image: 'star.svg',
    alt: 'Звезда',
  },
  {
    title: 'Быстро и удобно',
    image: 'time.svg',
    alt: 'Время',
  },
];
