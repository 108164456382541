import type { DivProps } from 'app.types';
import type { ReactNode } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

export type FaqTypes = DivProps;

export interface FaqCardTypes extends DivProps {
  data: FaqCardDataTypes[];
}

export interface FaqCardDataTypes {
  title: string;
  body: string | ReactNode;
}

export const classBlock = '.js-download-application';
export const analyticsEvent = gtmEvents.clickDownloadStoreFaq;
